<template>
  <div>
    <report-table />
  </div>
</template>

<script>
  import { mapActions, mapState, mapMutations } from 'vuex'
  import ReportTable from '../components/warehouse-reports/ReportTable.vue'
  export default {
    name: 'WarehouseReports',
    components: {
      ReportTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness', 'actionWarehouse']),
    },

    async created () {
      await this.fetchWarehouseConcessionNoApprove({ id: this.actionWarehouse, noApprove: true })
      await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      await this.getWarehousesByParentId({ id: this.user.businessId || this.actionsParentBusiness })
      await this.getStoreWarehouses()
      await this.getParentChilds(this.user.businessId || this.actionsParentBusiness)
      await this.getUsers()
      await this.fetchAllClient()
      await this.fetchProviders()
      this.setActions(Number(this.$route.params.id))
      this.setEnvironment('warehouse')
    },

    methods: {
      ...mapMutations({
        setEnvironment: 'SET_ACTIONS_ENVIRONMENT',
        setActions: 'SET_ACTIONS_WAREHOUSE',
      }),
      ...mapActions([
        'fetchProviders',
        'fetchWarehouseConcessionNoApprove',
        'fetchProducts',
        'getWarehousesByParentId',
        'getStoreWarehouses',
        'getUsers',
        'fetchAllClient',
        'getParentChilds',
      ]),
    },
  }
</script>
