<template>
  <v-container
    fluid
    tag="section"
  >
    <!-- {{ option }}
    <br>
    texto: {{ lastnameReport }} -->
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card
          icon="mdi-newspaper-variant"
          title="Tabla de reportes"
          class="px-5 py-3"
          color="orange"
        >
          <br>
          <br>
          <v-row>
            <v-col
              cols="12"
              md="10"
            >
              <v-autocomplete
                v-model="option"
                label="Seleccione el tipo de reporte"
                no-data-text="No hay coincidencia"
                outlined
                return-object
                item-text="name"
                :items="optionsReport"
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="warning"
                :disabled="option.name == undefined"
                @click="printPdf"
              >
                Generar reporte
              </v-btn>
            </v-col>

            <v-row
              v-if="option.type"
              justify="center"
            >
              <v-col
                v-if="option.filter === 'providers'"
                cols="12"
                sm="6"
                md="3"
                lg="2"
              >
                <!-- {{ providers }} -->
                <v-autocomplete
                  v-model="provider"
                  label="Proveedores"
                  :loading="fetchingStoreWarehouses"
                  :disabled="fetchingStoreWarehouses"
                  :items="[{name: 'TODOS', id: ''}, ...providers]"
                  item-text="name"
                  item-value="id"
                  no-data-text="No hay coincidencia"
                />
              </v-col>
              <!-- storeWarehouses -->
              <v-col
                v-if="option.filter === 'historic'"
                cols="12"
                sm="6"
                md="3"
                lg="2"
              >
                <!-- {{ storeWarehouses }} -->
                <v-autocomplete
                  v-model="rWarehouse"
                  label="Sucursal"
                  :loading="fetchingStoreWarehouses"
                  :disabled="fetchingStoreWarehouses"
                  :items="[{business: 'TODOS', warehouseId: ''}, ...storeWarehouses]"
                  item-text="business"
                  item-value="warehouseId"
                  no-data-text="No hay coincidencia"
                />
              </v-col>
              <v-col
                v-if="option.filter === 'historic'"
                cols="12"
                sm="6"
                md="3"
              >
                <!-- {{ products }} -->
                <v-autocomplete
                  v-model="product"
                  label="Productos"
                  :items="[{description: 'TODOS', id: ''}, ...products]"
                  item-text="description"
                  item-value="id"
                  :loading="fetchingStoreWarehouses"
                  :disabled="fetchingStoreWarehouses"
                  no-data-text="No hay coincidencia"
                />
              </v-col>
              <v-col
                v-if="option.filter === 'stores'"
                cols="12"
                sm="6"
                md="3"
                lg="2"
              >
                <!-- {{ businessParentsChilds }} -->
                <v-autocomplete
                  v-model="storeS"
                  label="Sucursal"
                  :loading="fetchingStoreWarehouses"
                  :disabled="fetchingStoreWarehouses"
                  :items="[{name: 'TODOS', id: ''}, ...businessParentsChilds]"
                  item-text="name"
                  item-value="id"
                  no-data-text="No hay coincidencia"
                />
              </v-col>
              <v-col
                v-if="option.filter === 'operations'"
                cols="12"
                sm="6"
                md="3"
                lg="2"
              >
                <!-- {{ providers }} -->
                <v-autocomplete
                  v-model="typeOperation"
                  label="Operacion"
                  :loading="fetchingStoreWarehouses"
                  :disabled="fetchingStoreWarehouses"
                  :items="typeFilter"
                  item-text="name"
                  item-value="id"
                  no-data-text="No hay coincidencia"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                lg="2"
              >
                <v-dialog
                  ref="dialog"
                  v-model="modalS"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(start)"
                      label="Fecha de inicio"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="start"
                    color="light-blue darken-1"
                    scrollable
                    locale="es-ve"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="modalS = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="modalS = false"
                    >
                      <!-- @click="$refs.dialog.save(start)" -->
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="3"
                lg="2"
              >
                <v-dialog
                  ref="dialog"
                  v-model="modalE"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(end)"
                      label="Fecha final"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="end"
                    scrollable
                    color="light-blue darken-1"
                    locale="es-ve"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="modalE = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="modalE = false"
                    >
                      <!-- @click="$refs.dialog.save(end)" -->
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-row>

          <!-- {{ option }} -->
          <v-row
            v-if="option.type === 4"
            justify="end"
          >
            <v-col
              class="text-right"
            >
              <h4>
                <span class="grey--text">Total de comisiones para el gerente: </span>
                <span class="green--text">{{ formatPrice(comisionsG) }}</span>
                <br>
                <!-- totalSellerCommission -->
              </h4>
            </v-col>
          </v-row>
          <v-row
            v-if="option.type === 4"
            justify="end"
          >
            <v-col
              class="text-right"
            >
              <h3>
                <span class="grey--text">Total de comisiones para la sucursal: </span>
                <span class="green--text">{{ formatPrice(comisionsT) }}</span>
                <br>
                <!-- totalSellerCommission -->
              </h3>
            </v-col>
          </v-row>
          <v-row
            v-if="option.type === 5"
            justify="end"
          >
            <v-col
              class="text-right"
            >
              <h3>
                <span class="grey--text">Total de comisiones por presupuesto: </span>
                <span class="green--text">{{ formatPrice(comisionsS) }}</span>
                <br>
                <!-- totalSellerCommission -->
              </h3>
            </v-col>
          </v-row>
          <v-row
            v-if="(option.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion && option.type !== 2 && option.type !== 3) || option.type === 1"
            justify="end"
          >
            <v-col
              class="text-right"
            >
              <h1>
                <span class="grey--text">Total:</span> <span class="green--text">{{ formatPrice(total) }}</span>
                <br>
                <!-- totalSellerCommission -->
              </h1>
            </v-col>
          </v-row>
          <v-row
            v-if="option.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion && (option.type === 2 || option.type === 3)"
            justify="end"
          >
            <v-col
              class="text-right"
            >
              <h1>
                <span class="grey--text">Precio total del material: </span>
                <span class="green--text">{{ formatPrice(totalMaterial) }}</span>
                <br>
                <!-- totalSellerCommission -->
              </h1>
            </v-col>
          </v-row>
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busca por cualquier campo"
              single-line
              hide-details
            />
          </v-container>
          <v-data-table
            :headers="option.type === 2 || option.type === 3 ? headersAlt : option.type === 7 ? headersC : headers"
            :items="reports"
            item-key="_id"
            :search="search"
            :loading="fetchingReports"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:top>
              <sales-table-top />
            </template>
            <template v-slot:[`item.description`]="{ item: input }">
              <span v-if="input.description">
                {{ input.description }}
              </span>
              <span v-else>
                ...
              </span>
            </template>
            <template v-slot:[`item.totalP`]="{ item }">
              <span
                :style="{ color: 'green' }"
              >
                <!-- {{ formatPrice(item.totalAmountCurrencyReference - (item.totalAmountCurrencyReference * (item.discount / 100))) }} -->
                {{ formatPrice (Math.abs(item.totalP)) }}
              </span>
            </template>
            <template v-slot:[`item.cantP`]="{ item }">
              <span>
                <!-- {{ formatPrice(item.totalAmountCurrencyReference - (item.totalAmountCurrencyReference * (item.discount / 100))) }} -->
                {{ Math.abs(item.cantP) }}
              </span>
            </template>e>
            <template v-slot:[`item.packingCantP`]="{ item }">
              <span>
                <!-- {{ formatPrice(item.totalAmountCurrencyReference - (item.totalAmountCurrencyReference * (item.discount / 100))) }} -->
                {{ Math.abs(item.packingCantP) }}
              </span>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span
                :style="{ color: 'gray' }"
              >
                {{ formatPrice(item.amount) }}
              </span>
            </template>
            <template v-slot:[`item.amountDiscount`]="{ item }">
              <span
                :style="{ color: 'gray' }"
              >
                {{ formatPrice(Math.abs(item.amountDiscount)) }}
              </span>
            </template>
            <template v-slot:[`item.totalAmountMaterial`]="{ item }">
              <span
                :style="{ color: 'green' }"
              >
                {{ formatPrice(item.totalAmountMaterial) }}
              </span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item: input }">
              {{ new Date(input.createdAt).getDate() }}/{{ new Date(input.createdAt).getMonth() + 1 }}/{{ new Date(input.createdAt).getFullYear() }}
            </template>

            <template v-slot:[`item.typeTransaction`]="{ item: input }">
              <span
                v-if="input.typeTransactionId === TYPE_TRANSACTION.concesion"
                class="orange--text"
              >
                <!-- Solicitud de concesión -->
                Despacho
              </span>
              <span
                v-else-if="input.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion"
                class="green--text"
              >
                Presupuesto
              </span>
              <span
                v-else-if="input.typeTransactionId === TYPE_TRANSACTION.devolucion_por_concesion"
                class="red--text"
              >
                Devolución
              </span>
              <span
                v-else-if="input.typeTransactionId === TYPE_TRANSACTION.salida"
                class="red--text"
              >
                Salida
              </span>
            </template>

            <template v-slot:[`item.input`]="{ item: input }">
              <span
                v-if="input.typeTransactionId === TYPE_TRANSACTION.concesion"
                class="red--text"
              >
                <!-- Solicitud de concesión -->
                {{ Math.abs(input.cantP) }}
              </span>
            </template>

            <template v-slot:[`item.output`]="{ item: input }">
              <!-- Solicitud de concesión -->
              <span
                v-if="input.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion || input.typeTransactionId === TYPE_TRANSACTION.devolucion_por_concesion"
                class="green--text"
              >
                {{ Math.abs(input.cantP) }}
              </span>
            </template>

            <template v-slot:[`item.approved`]="{ item: item }">
              <v-icon
                v-if="item.approved"
                color="green"
              >
                mdi-check-all
              </v-icon>

              <v-icon
                v-else
                color="gray"
              >
                mdi-check
              </v-icon>
              <!-- <h1>hioh</h1> -->
            </template>

            <template v-slot:[`item.actions`]="{ item: item }">
              <!-- {{ option.type }} -->
              <sales-actions
                :option="option"
                :item="item"
              />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import SalesTableTop from './SalesTableTop'
  import SalesActions from './SalesActions.vue'
  // import ConcessionRequestActions from './ConcessionRequestActions.vue'
  import { TYPE_TRANSACTION, PRINCIPAL_WAREHOUSE } from '../../../../const'
  import { jsPDF as JsPdf } from 'jspdf'
  import { DateTime } from 'luxon'

  export default {
    name: 'ConcessionRequestTable',
    components: {
      SalesTableTop,
      SalesActions,
      // ConcessionRequestActions,
    },
    data () {
      return {
        TYPE_TRANSACTION,
        product: '',
        rWarehouse: '',
        typeOperation: '',
        provider: '',
        storeS: '',
        option: {},
        start: '',
        end: '',
        relatedWarehouseId: '',
        search: '',
        modalS: false,
        modalE: false,
        headersAlt: [
          {
            text: 'id',
            value: 'id',
          },
          {
            text: 'Código',
            value: 'productCode',
          },
          {
            text: 'Nombre del producto',
            value: 'productName',
          },
          {
            text: 'Tipo de transaccion',
            value: 'typeTransaction',
          },
          {
            text: 'Rollos',
            value: 'totalPacking',
          },
          {
            text: 'KG',
            value: 'totalCant',
          },
          {
            text: 'Monto de venta',
            value: 'amount',
          },
          {
            text: 'Monto de descuentos',
            value: 'amountDiscount',
          },
          {
            text: 'Total',
            value: 'totalAmountMaterial',
          },
          {
            text: 'Acciones',
            value: 'actions',
            align: 'center',
            sortable: false,
          },
        ],
        headers: [
          {
            text: 'Fecha',
            value: 'createdAt',
          },
          {
            text: 'C de transacción',
            // text: 'Código',
            value: 'code',
          },
          {
            text: 'Producto',
            value: 'productName',
          },
          {
            text: 'Tipo de transaccion',
            value: 'typeTransaction',
          },
          {
            text: 'Total Rollos',
            value: 'packingCantP',
          },
          {
            text: 'Total KG',
            value: 'cantP',
          },
          {
            text: 'Precio unitario',
            value: 'amount',
          },
          {
            text: 'Total',
            value: 'totalP',
          },
          {
            text: 'Observaciones',
            value: 'description',
          },
          {
            text: 'Estatus',
            value: 'approved',
          },
          // {
          //   text: 'Acciones',
          //   value: 'actions',
          //   align: 'center',
          //   sortable: false,
          // },
        ],
        headersC: [
          {
            text: 'Fecha',
            value: 'createdAt',
          },
          {
            text: 'C de transacción',
            value: 'code',
          },
          {
            text: 'Producto',
            value: 'productName',
          },
          {
            text: 'Tipo de transaccion',
            value: 'typeTransaction',
          },
          {
            text: 'Total Rollos',
            value: 'packingCantP',
          },
          {
            text: 'Total KG',
            value: 'cantP',
          },
          {
            text: 'Entrada (KG)',
            value: 'input',
          },
          {
            text: 'Salida (KG)',
            value: 'output',
          },
          // {
          //   text: 'Acciones',
          //   value: 'actions',
          //   align: 'center',
          //   sortable: false,
          // },
        ],
        typeFilter: [
          {
            name: 'TODAS',
            value: 0,
          },
          {
            name: 'PRESUPUESTOS',
            value: 1,
          },
          {
            name: 'DESPACHOS',
            value: 2,
          },
          {
            name: 'DEVOLUCIONES',
            value: 3,
          },
          {
            name: 'SALIDAS',
            value: 4,
          },
        ],
      }
    },

    computed: {
      ...mapState([
        'fetchingBusinessSales',
        'businessSales',
        'fetchingStoreWarehouses',
        'storeWarehouses',
        'actionWarehouse',
        'fetchingReports',
        'reports',
        'warehouses',
        'fetchingWarehouses',
        'users',
        'fetchingUser',
        'fecthingClients',
        'products',
        'fetchingProducts',
        'clients',
        'businessParentsChilds',
        'providers',
        'reports',
        'fetchingReports',
      ]),

      getStoreWarhouses () {
        return [{ business: 'TODAS', warehouseId: '' }, ...this.storeWarehouses]
      },

      totalMaterial () {
        // // // console.log(this.reports)
        let res = 0

        this.reports.forEach(item => {
          // // // console.log(item.discount)
          res = res + item.totalAmountMaterial
        })

        return res
      },

      totalAmount () {
        // // // console.log(this.reports)
        let res = 0

        this.reports.forEach(item => {
          // // // console.log(item.discount)
          res = res + item.amount
        })

        return res
      },

      totalDiscount () {
        // // // console.log(this.reports)
        let res = 0

        this.reports.forEach(item => {
          // // // console.log(item.amountDiscount)
          res = res + item.amountDiscount
        })

        return res
      },

      total () {
        // // // console.log(this.reports)
        let res = 0

        this.reports.forEach(item => {
          // // // console.log(item.discount)
          // res = res + (item.totalAmountCurrencyReference - (item.totalAmountCurrencyReference * (item.discount / 100)))
          res = res + (item.totalP - (item.totalP * (item.discount / 100)))
          // // // console.log(item.code, res)
        })

        return res
      },

      totalCant () {
        // // // console.log(this.reports)
        let res = 0

        this.reports.forEach(item => {
          res = res + item.totalUnits
        })

        return res
      },

      totalCantMaterial () {
        // // // console.log(this.reports)
        let res = 0

        this.reports.forEach(item => {
          res = res + item.totalCant
        })

        return res
      },

      totalPacking () {
        // // // console.log(this.reports)
        let res = 0

        this.reports.forEach(item => {
          res = res + item.totalPacking
        })

        return res
      },

      comisionsG () {
        // // // console.log(this.reports)
        let res = 0

        this.reports.forEach(item => {
          res = res + (item.managerCommission - (item.managerCommission * (item.discount / 100)))
        })

        return res
      },

      comisionsS () {
        // // // console.log(this.reports)
        let res = 0

        this.reports.forEach(item => {
          res = res + (item.sellerCommission - (item.sellerCommission * (item.discount / 100)))
        })

        return res
      },

      comisionsT () {
        // // // console.log(this.reports)
        let res = 0

        this.reports.forEach(item => {
          // // // console.log((item.managerCommission - (item.managerCommission * (item.discount / 100))))
          // // // console.log((item.sellerCommission - (item.sellerCommission * (item.discount / 100))))

          res = res + ((item.sellerCommission - (item.sellerCommission * (item.discount / 100))) + (item.managerCommission - (item.managerCommission * (item.discount / 100))))
        })

        return res
      },

      totalInput () {
        let res = 0

        this.reports.forEach(item => {
          res = item.typeTransactionId === TYPE_TRANSACTION.concesion ? res + item.cantP : res
        })

        return Math.abs(res)
      },

      totalOutput () {
        let res = 0

        this.reports.forEach(item => {
          res = item.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion || item.typeTransactionId === TYPE_TRANSACTION.devolucion_por_concesion ? res + item.cantP : res
        })

        return Math.abs(res)
      },

      lastnameReport () {
        let res = `ENTRE ${this.start} A ${this.end} `
        // // console.log(this.option.type)
        if (this.option.type === 1) {
          if (this.provider !== '') {
            // // console.log(this.provider)
            // // console.log(this.providers.filter(item => item.id === this.provider))
            res += `DE ${this.providers.filter(item => item.id === this.provider)[0].name.toUpperCase()}`
          }
        }

        if (this.option.type === 4 || this.option.type === 3) {
          if (this.storeS !== '') {
            res += `DE ${this.businessParentsChilds.filter(item => item.id === this.storeS)[0].name.toUpperCase()}`
          }
        }

        if (this.option.type === 7) {
          if (this.product) {
            // // console.log(this.products)
            res += `DE ${this.products.filter(item => item.id === this.product)[0].description.toUpperCase()}`
          }

          if (this.rWarehouse) {
            // // console.log(this.storeWarehouses)

            res += ` EN ${this.storeWarehouses.filter(item => item.warehouseId === this.rWarehouse)[0].business.toUpperCase()}`
          }
        }
        return res
      },

      optionsReport () {
        const transactions = [
          {
            type: 1,
            filter: 'providers',
            name: 'ENTRADAS',
            id: TYPE_TRANSACTION.entrada,
          },
          {
            type: 6,
            filter: 'salidas',
            name: 'SALIDAS',
            id: [
              TYPE_TRANSACTION.salida,
              TYPE_TRANSACTION.concesion,
              TYPE_TRANSACTION.devolucion_por_concesion,
              TYPE_TRANSACTION.venta_por_concesion,
            ],
          },
          // {
          //   type: 1,
          //   name: 'DESPACHO',
          //   id: TYPE_TRANSACTION.concesion,
          // },
          // {
          //   type: 1,
          //   name: 'PRESUPUESTOS',
          //   id: TYPE_TRANSACTION.venta_por_concesion,
          // },
          // {
          //   type: 2,
          //   name: 'CORTE DE MATERIAL',
          //   id: TYPE_TRANSACTION.venta_por_concesion,
          // },
          {
            filter: 'stores',
            type: 3,
            name: 'CORTE DE MATERIAL',
            id: TYPE_TRANSACTION.venta_por_concesion,
          },
          {
            filter: 'stores',
            type: 4,
            name: 'COMISIONES GENERALES',
            id: TYPE_TRANSACTION.venta_por_concesion,
          },
          {
            filter: 'historic',
            type: 7,
            name: 'HISTORICO DE TRANSACCIONES',
            id: [
              TYPE_TRANSACTION.concesion,
              TYPE_TRANSACTION.devolucion_por_concesion,
              TYPE_TRANSACTION.venta_por_concesion,
            ],
          },
          // {
          //   type: 5,
          //   name: 'COMISIONES POR PRESUPUESTO',
          //   id: TYPE_TRANSACTION.venta_por_concesion,
          // },
        ]
        const res = []

        transactions.forEach(item => {
          // for (let i = 1; i < 53; i++) {
          if (item.id === TYPE_TRANSACTION.entrada || item.type === 6 || item.type === 7) { // Transacciones que tiene que ver con proveedores)
            // this.providers.filter(item => item.enable).forEach(provider => {
            // // // // console.log({ name: `${item.name} DE ${provider.name.toUpperCase()} SEMANA ${i}`, value: item.id })
            // // // console.log(provider.id)
            res.push({
              ...item,
              type: item.type,
              name: `${item.name}`,
              warehouseId: PRINCIPAL_WAREHOUSE,
              typeTransactionId: item.id,
              start: this.start,
              end: this.end,
              // name: `${item.name} DE ${provider.name.toUpperCase()}`,
              // start: this.getFirstDayOfWeek(i),
              // end: this.getLastDayOfWeek(i),
              // providerId: provider.id,
            })
            // })
          } else if (item.id === TYPE_TRANSACTION.concesion) {
            this.storeWarehouses.forEach(store => {
              // // // // console.log('despacho', item)
              res.push({
                type: item.type,
                name: `${item.name} A ${store.business}`,
                typeTransactionId: item.id,
                warehouseId: store.warehouseId,
                relatedWarehouseId: PRINCIPAL_WAREHOUSE,
                start: this.start,
                end: this.end,
              })
            })
          } else if (item.id === TYPE_TRANSACTION.venta_por_concesion && (item.type === 1 || item.type === 3 || item.type === 4)
          ) { // Transacciones que tiene que ver con tiendas ( Despachos, Ventas )
            // this.businessParentsChilds.filter(item => item.status).forEach(store => {
            // // // // console.log(store)
            res.push({
              ...item,
              type: item.type,
              // name: `${item.name} DE LA SUCURSAL ${store.name}`,
              name: `${item.name}`,
              typeTransactionId: item.id,
              relatedWarehouseId: PRINCIPAL_WAREHOUSE,
              start: this.start,
              end: this.end,
              // businessId: store.id,
            })
            // })
          } else if (item.type === 2) {
            res.push({
              ...item,
              type: item.type,
              name: `${item.name} TOTAL`,
              typeTransactionId: item.id,
              warehouseId: PRINCIPAL_WAREHOUSE,
              start: this.start,
              end: this.end,
            })
          } else if (item.type === 5) {
            this.users.filter(item => item.enable).forEach(user => {
              // // // // console.log(user)
              res.push({
                ...item,
                type: item.type,
                name: `${item.name} DE (${user.ci}) ${user.name.toUpperCase()} ${user.lastName.toUpperCase()}`,
                typeTransactionId: item.id,
                start: this.start,
                end: this.end,
                relatedWarehouseId: PRINCIPAL_WAREHOUSE,
                userId: user.id,
              })
            })
          }
          // }
        })
        // res.push({
        //   type: 2,
        //   name: 'CORTE DIARIO DE MATERIAL TOTAL',
        //   typeTransactionId: TYPE_TRANSACTION.venta_por_concesion,
        //   warehouseId: PRINCIPAL_WAREHOUSE,
        //   start: DateTime.now().toFormat('yyyy-MM-dd'),
        //   end: DateTime.now().toFormat('yyyy-MM-dd'),
        //   // start: '2022-06-05',
        // })

        // this.businessParentsChilds.filter(item => item.status).forEach(store => {
        //   // // // // console.log(store)
        //   res.push({
        //     type: 3,
        //     name: `CORTE DIARIO DE MATERIAL DE LA SUCURSAL ${store.name}`,
        //     typeTransactionId: TYPE_TRANSACTION.venta_por_concesion,
        //     relatedWarehouseId: PRINCIPAL_WAREHOUSE,
        //     start: DateTime.now().toFormat('yyyy-MM-dd'),
        //     end: DateTime.now().toFormat('yyyy-MM-dd'),
        //     businessId: store.id,
        //   })
        // })
        return res
      },
    },

    watch: {
      async option (v) {
        // // // console.log(v)
        this.provider = ''
        this.storeS = ''
        this.rWarehouse = ''
        this.product = ''
        if (v.type) {
          // await this.fetchReportsMaterial(v)
          if (v.type === 2 || v.type === 3) {
            await this.fetchReportsMaterial(v)
          } else {
            await this.fetchReports(v)
          }
        }
      },

      async provider () {
        // // console.log('update start')
        const newOption = {
          ...this.option,
          start: this.start,
          end: this.end,
          providerId: this.provider,
        }
        if (this.option.type) {
          // await this.fetchReportsMaterial(newOption)
          if (this.option.type === 2 || this.option.type === 3) {
            await this.fetchReportsMaterial(newOption)
          } else {
            await this.fetchReports(newOption)
          }
        }
      },

      async storeS () {
        // // console.log('update start')
        const newOption = {
          ...this.option,
          start: this.start,
          end: this.end,
          businessId: this.storeS,
        }

        if (this.option.type) {
          // await this.fetchReportsMaterial(newOption)
          if (this.option.type === 2 || this.option.type === 3) {
            await this.fetchReportsMaterial(newOption)
          } else {
            await this.fetchReports(newOption)
          }
        }
      },

      async rWarehouse () {
        // // console.log('update start')
        const newOption = {
          ...this.option,
          start: this.start,
          end: this.end,
          productId: this.product,
          relatedWarehouseId: this.rWarehouse,
        }

        if (this.option.type) {
          // await this.fetchReportsMaterial(newOption)
          if (this.option.type === 2 || this.option.type === 3) {
            await this.fetchReportsMaterial(newOption)
          } else {
            await this.fetchReports(newOption)
          }
        }
      },

      async product () {
        // // console.log('update start')
        const newOption = {
          ...this.option,
          start: this.start,
          end: this.end,
          productId: this.product,
          relatedWarehouseId: this.rWarehouse,
        }

        if (this.option.type) {
          // await this.fetchReportsMaterial(newOption)
          if (this.option.type === 2 || this.option.type === 3) {
            await this.fetchReportsMaterial(newOption)
          } else {
            await this.fetchReports(newOption)
          }
        }
      },

      async start () {
        // // console.log('update start')
        const newOption = {
          ...this.option,
          start: this.start,
          end: this.end,
        }

        if (this.provider) {
          newOption.providerId = this.provider
        }

        if (this.storeS) {
          newOption.businessId = this.storeS
        }

        if (this.rWarehouse) {
          newOption.relatedWarehouseId = this.rWarehouse
        }

        if (this.product) {
          newOption.productId = this.product
        }

        if (this.option.type) {
          // await this.fetchReportsMaterial(newOption)
          if (this.option.type === 2 || this.option.type === 3) {
            await this.fetchReportsMaterial(newOption)
          } else {
            await this.fetchReports(newOption)
          }
        }
      },

      async end () {
        // // console.log('update end')
        const newOption = {
          ...this.option,
          start: this.start,
          end: this.end,
        }

        if (this.provider) {
          newOption.providerId = this.provider
        }

        if (this.storeS) {
          newOption.businessId = this.storeS
        }

        if (this.rWarehouse) {
          newOption.relatedWarehouseId = this.rWarehouse
        }

        if (this.product) {
          newOption.productId = this.product
        }

        if (this.option.type) /*! this.initialData */ {
          // await this.fetchReportsMaterial(newOption)
          if (this.option.type === 2 || this.option.type === 3) {
            await this.fetchReportsMaterial(newOption)
          } else {
            await this.fetchReports(newOption)
          }
        }
      },
    },

    created () {
      this.end = DateTime.fromJSDate(this.nextWeek()).toFormat('yyyy-MM-dd')
      this.start = DateTime.fromJSDate(this.lastWeek()).toFormat('yyyy-MM-dd')
    },

    methods: {
      ...mapActions([
        'fetchReports',
        'fetchReportsMaterial',
        'fetchWarhouseInputsDetail',
        'getBusinessByWarehousesId',
        'fetchClientById',
        'fetchBusinessSalesPayments',
        'fetchBusinessSalesSurplus',
      ]),

      // formatDate (dateProp) {
      //   return DateTime
      //     .fromFormat(dateProp, 'yyyy-MM-dd')
      //     .toFormat('dd/MM/yyyy')
      // },

      lastWeek () {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
      },

      nextWeek () {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
      },

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },

      formatDate (dateProp) {
        const date = new Date(dateProp)

        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = new Date(year, month, date.getDate() + 1).getDate()

        if (month < 10) {
          return (`${day}/0${month}/${year}`)
        } else {
          return (`${day}/${month}/${year}`)
        }
      },

      getFirstDayOfWeek (week) {
        const dt = DateTime.fromObject({
          weekYear: new Date().getFullYear(),
          weekNumber: week,
        })

        const dateFromStr = dt.startOf('week')
        return dateFromStr.toFormat('yyyy-MM-dd')
      },

      getLastDayOfWeek (week) {
        const dt = DateTime.fromObject({
          weekYear: new Date().getFullYear(),
          weekNumber: week,
        })

        const dateFromStr = dt.endOf('week')
        return dateFromStr.toFormat('yyyy-MM-dd')
      },

      async submit () {
        await this.fetchReports(this.option)
      },

      async genPdf (print = false) {
        // // // // // // // // // // // // // // // // console.log('ITEM', this.item)
        const doc = new JsPdf({
          orientation: 'portrait',
          format: 'letter',
        })

        // doc.addImage(img, 'PNG', 10, 5, 100, 20, 'logo')
        doc.setFontSize(38).text('Sistema Genérico', 10, 23)
        doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(new Date())}`, 145, 23, { align: 'left' })

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12).setTextColor(0, 0, 0).text(`${this.option.name} ${this.lastnameReport}`, doc.internal.pageSize.width / 2, 40, { align: 'center', maxWidth: 170 })

        if (this.option.type === 7) {
          doc.autoTable({
            styles: { halign: 'center' },
            columns: [
              { title: 'Fecha', dataKey: 'date' },
              { title: 'Transaccion', dataKey: 'code' },
              { title: 'Producto', dataKey: 'productName' },
              { title: 'Total Rollos', dataKey: 'packingCantP' },
              { title: 'Total KG', dataKey: 'cantP' },
              { title: 'Entradas', dataKey: 'input' },
              { title: 'Salidas', dataKey: 'output' },
              // { title: 'Total', dataKey: 'total' }],
            ],
            body: this.reports.map(item => {
              return {
                ...item,
                date: this.formatDate(item.createdAt),
                statuse: item.approved ? 'APROBADO' : 'SIN APROBAR',
                total: this.formatPrice(item.totalAmountCurrencyReference - (item.totalAmountCurrencyReference * (item.discount / 100))),
                packingCantP: Math.abs(item.packingCantP),
                cantP: Math.abs(item.cantP),
                input: item.typeTransactionId === TYPE_TRANSACTION.concesion ? Math.abs(item.cantP) : '',
                output: item.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion || item.typeTransactionId === TYPE_TRANSACTION.devolucion_por_concesion ? Math.abs(item.cantP) : '',
              }
            }),
            margin: {
              left: 10,
              top: 50,
            },
          })

          doc.setLineWidth(0.5).line(15, doc.internal.pageSize.height - 25, doc.internal.pageSize.width - 15, doc.internal.pageSize.height - 25)

          doc.setFontSize(15).text(`Total de inventario: ${this.totalInput - this.totalOutput} KG`, 15, doc.internal.pageSize.height - 20)
          // doc.setFontSize(10).text(String(this.totalPacking), doc.internal.pageSize.width / 3 + 69, doc.internal.pageSize.height - 20, { align: 'center' })
          doc.setFontSize(10).text(String(this.totalInput), doc.internal.pageSize.width - doc.internal.pageSize.width / 2 + 73, doc.internal.pageSize.height - 20, { align: 'center' })
          doc.setFontSize(10).text(String(this.totalOutput), doc.internal.pageSize.width - doc.internal.pageSize.width / 2 + 87, doc.internal.pageSize.height - 20, { align: 'center' })
          // doc.setFontSize(12).text(`${this.formatPrice(this.total)}`, doc.internal.pageSize.width - doc.internal.pageSize.width / 2 + 83, doc.internal.pageSize.height - 20, { align: 'center' })
        } else if (this.option.type !== 2 && this.option.type !== 3) {
          doc.autoTable({
            styles: { halign: 'center' },
            columns: [{ title: 'Fecha', dataKey: 'date' },
                      { title: 'Código', dataKey: 'code' },
                      { title: 'Observaciones', dataKey: 'description' },
                      { title: 'Estatus', dataKey: 'statuse' },
                      { title: 'Total Rollos', dataKey: 'totalPacking' },
                      { title: 'Total KG', dataKey: 'totalUnits' },
                      { title: 'Total', dataKey: 'total' }],
            body: this.reports.map(item => {
              return {
                ...item,
                date: this.formatDate(item.createdAt),
                statuse: item.approved ? 'APROBADO' : 'SIN APROBAR',
                total: this.formatPrice(item.totalAmountCurrencyReference - (item.totalAmountCurrencyReference * (item.discount / 100))),
              }
            }),
            margin: {
              left: 10,
              top: 50,
            },
          })

          doc.setLineWidth(0.5).line(15, doc.internal.pageSize.height - 25, doc.internal.pageSize.width - 15, doc.internal.pageSize.height - 25)

          doc.setFontSize(15).text('Totales', 15, doc.internal.pageSize.height - 20)
          doc.setFontSize(10).text(String(this.totalPacking), doc.internal.pageSize.width / 3 + 69, doc.internal.pageSize.height - 20, { align: 'center' })
          doc.setFontSize(10).text(String(this.totalCant), doc.internal.pageSize.width / 3 + 93, doc.internal.pageSize.height - 20, { align: 'center' })
          doc.setFontSize(12).text(`${this.formatPrice(this.total)}`, doc.internal.pageSize.width - doc.internal.pageSize.width / 2 + 83, doc.internal.pageSize.height - 20, { align: 'center' })

          if (this.option.type === 4) {
            // doc.setFontSize(13).text('Total de comisiones para la sucursal: ', 15, doc.internal.pageSize.height - 17)
            // doc.setFontSize(10).text(this.formatPrice(this.comisionsT), doc.internal.pageSize.width - doc.internal.pageSize.width / 2 + 83, doc.internal.pageSize.height - 17, { align: 'center' })

            doc.setFontSize(12).text('Total de comisiones para gerente:', 15, doc.internal.pageSize.height - 10)
            doc.setFontSize(10).text(this.formatPrice(this.comisionsG), doc.internal.pageSize.width - doc.internal.pageSize.width / 2 + 83, doc.internal.pageSize.height - 10, { align: 'center' })
          } else if (this.option.type === 5) {
            doc.setFontSize(13).text('Total de comisiones por presupuesto: ', 15, doc.internal.pageSize.height - 17)
            doc.setFontSize(10).text(this.formatPrice(this.comisionsS), doc.internal.pageSize.width - doc.internal.pageSize.width / 2 + 83, doc.internal.pageSize.height - 17, { align: 'center' })
          }
        } else {
          doc.autoTable({
            styles: { halign: 'center' },
            columns: [{ title: 'Código', dataKey: 'productCode' },
                      { title: 'Nombre del procucto', dataKey: 'productName' },
                      { title: 'Rollos', dataKey: 'totalPacking' },
                      { title: 'KG', dataKey: 'totalCant' },
                      { title: 'Monto de venta', dataKey: 'amount' },
                      { title: 'Monto de descuento', dataKey: 'amountDiscount' },
                      { title: 'Total', dataKey: 'totalAmountMaterial' }],
            body: this.reports.map(item => {
              return {
                ...item,
                date: this.formatDate(item.createdAt),
                statuse: item.approved ? 'APROBADO' : 'SIN APROBAR',
                total: this.formatPrice(item.totalAmountCurrencyReference - (item.totalAmountCurrencyReference * (item.discount / 100))),
                amount: this.formatPrice(item.amount),
                amountDiscount: this.formatPrice(Math.abs(item.amountDiscount)),
                totalAmountMaterial: this.formatPrice(item.totalAmountMaterial),
              }
            }),
            margin: {
              left: 10,
              top: 50,
            },
          })

          doc.setLineWidth(0.5).line(15, doc.internal.pageSize.height - 25, doc.internal.pageSize.width - 15, doc.internal.pageSize.height - 25)

          doc.setFontSize(15).text('Totales', 15, doc.internal.pageSize.height - 20)
          doc.setFontSize(10).text(String(this.totalPacking), doc.internal.pageSize.width / 3 + 16, doc.internal.pageSize.height - 20, { align: 'center' })
          doc.setFontSize(10).text(String(this.totalCantMaterial), doc.internal.pageSize.width / 3 + 29, doc.internal.pageSize.height - 20, { align: 'center' })
          doc.setFontSize(10).text(this.formatPrice(this.totalAmount), doc.internal.pageSize.width / 3 + 49, doc.internal.pageSize.height - 20, { align: 'center' })
          doc.setFontSize(10).text(this.formatPrice(Math.abs(this.totalDiscount)), doc.internal.pageSize.width / 3 + 86, doc.internal.pageSize.height - 20, { align: 'center' })
          doc.setFontSize(12).text(`${this.formatPrice(this.totalMaterial)}`, doc.internal.pageSize.width - doc.internal.pageSize.width / 2 + 83, doc.internal.pageSize.height - 20, { align: 'center' })
        }

        // doc.autoPrint()
        // doc.output('dataurlnewwindow')
        if (print) {
          doc.autoPrint()
          doc.output('dataurlnewwindow')
        } else {
          doc.save('nota-de-entrega.pdf')
        }
      },

      async printPdf () {
        // await this.fetchWarhouseInputsDetail({ id: this.item.id })
        // await this.getBusinessByWarehousesId({ id: this.item.relatedWarehouseId })
        // await this.fetchClientById(this.item.clientId)
        // await this.fetchBusinessSalesPayments({ id: this.item.id })
        // await this.fetchBusinessSalesSurplus({ id: this.item.id })
        await this.genPdf(true)
      },
    },
  }
</script>
